<template>
  <div class="m-dxby-header-wrap">
    <div class="m-dxby-header">
      <section class="m-dxby-header-1">
        <div
          @click="$router.back(-1)"
          class="m-dxby-header-1-item"
          v-show="backIsShow"
        >
          <van-image
            :src="arrow"
            fit="contain"
            class="goBack"
          />
        </div>
        <div class="m-dxby-header-1-item">
          <router-link to="/">
            <van-image
              width="151"
              :src="logo"
              class="logo"
              alt="鸿仁大新布业LOGO"
            />
          </router-link>
        </div>
        <div class="m-dxby-header-1-item"></div>
      </section>
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from 'vant'
import { LOGO } from '@/utils/imageManage'
import arrow from '@/assets/images/index/leftarrow.png'

export default {
  name: 'heads',
  props: {
    backIsShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShow: false,
      logo: LOGO,
      arrow
    }
  },
  components: {
    VanImage
  }
}
</script>

<style scoped lang="less">
.m-dxby-header-wrap {
  .m-dxby-header {
    padding: 9px 12px 12px;
    height: 60px;
    background-color: #fff;
    overflow: hidden;
    .m-dxby-header-1 {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: 6px;
      .m-dxby-header-1-item {
        .goBack {
          width: 30px;
        }
      }
      .m-dxby-header-1-item:first-child {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
