/**
 * @description 管理页面公共图片和图标
 * @author YouRan
 */
/* 公共 */
// LOGO
export const LOGO = require('@/assets/images/index/logo.png')
// 返回顶部按钮
export const BACKTOP = require('@/assets/images/index/back.png')
// 商城二维码入口
export const WEISHANGCHEN = require('@/assets/images/index/weishangchen.png')
// 视频封面图
export const INDEX_VEDIO_ALBUM = 'https://hrdaxin-mall.oss-cn-guangzhou.aliyuncs.com/company/albumCover.png'

/* 首页 */
// 公司门店视频
export const COMPANY_VDIDEO = 'https://hrdaxin-mall.oss-cn-guangzhou.aliyuncs.com/company/shop.MP4'
// 产品图横幅 THE HIGH QUALITY
export const QUALITYIMG = 'images/index/gzl-adv.jpg'
// 产品图横幅 甄选新疆精棉
export const QUALITYIMG2 = 'images/index/buy.jpg'
// 店铺主营业务
export const MAINSBUSINESS = 'images/index/gysBigImg.jpg'
// 品控相册
export const PK_IMG = 'images/index/pkong.jpg'

/* 公司简介 */
// 公司360全景图
// export const COMPANY_360FULLSCREEN = 'images/introduce/360quanjing.jpg'
export const COMPANY_360FULLSCREEN = 'https://hrdaxin-mall.oss-cn-guangzhou.aliyuncs.com/company/360quanjing.jpg'
// 公司介绍大图
export const COMPANY_INTRODUCE_IMG = 'https://hrdaxin-mall.oss-cn-guangzhou.aliyuncs.com/company/m_gsjj.jpg'
// 公司简介标题
export const COMPANY_TITLE = require('@/assets/images/introduce/title1.png')
// 我们的优势标题
export const COMPANY_ADV_TITLE = require('@/assets/images/introduce/title2.png')
// 公司优势ICON - 工厂源头
export const COMPANY_ADV_ICON1 = require('@/assets/images/introduce/jjIcon01.png')
// 公司优势ICON - 技术品控
export const COMPANY_ADV_ICON2 = require('@/assets/images/introduce/jjIcon02.png')
// 公司优势ICON - 配送系统
export const COMPANY_ADV_ICON3 = require('@/assets/images/introduce/jjIcon03.png')
// 公司优势ICON - 品质服务
export const COMPANY_ADV_ICON4 = require('@/assets/images/introduce/jjIcon04.png')

/* 联系我们 */
// 地图标记icon
export const MAP_ICON = require('@/assets/images/contact/mark.png')
// 点击全屏显示icon
export const FULL_SCREEN_ICON = require('@/assets/images/contact/fullscreen.png')

/* 产品中心 */
// 微信小程序商城
export const WX_MALL = 'images/index/ewm01.jpg'
// 1688小程序商城
export const ALI_MALL_1688 = 'images/index/ewm02.jpg'
// 微信公众号小程序商城
export const WX_OFFICIAL_ACCOUNT = 'images/index/ewm03.jpg'
// 抖音小程序商城
export const DOUYIN_MALL = 'images/index/ewm04.jpg'

/* 产品中心 - 详情 */
export const CLOSE_ICON = require('@/assets/images/prodction/close.png')

/* 公司动态 */
export const TOPPING_ICON = require('@/assets/images/news/Topping.png')

/* 搜索 */
export const searchImg = require('@/assets/images/search/empty-box.png')
