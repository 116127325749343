<template>
  <footer class="foot" :class="isShowBottomBorder ? 'borderTop' : ''">
    <div class="foot-wrap">
      <div v-html="copyRight"></div>
    </div>
  </footer>
</template>

<script>
import { copyRight } from '@/utils/constantManage'

export default {
  name: 'foots',
  props: {
    isShowBottomBorder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      copyRight
    }
  }
}
</script>

<style scoped lang="less">
.borderTop {
  border-top: 1px solid #e0e0e0;
}
.foot {
  width: 100%;
  text-align: center;
  padding: 25px 0 30px;
  /deep/.foot-wrap {
    width: 70%;
    margin: 0 auto;
    div {
      font-size: 14px;
      color: #333;
      p {
        line-height: 25px;
      }
    }
  }
}
a {
  color: #333;
  font-size: 14px;
}
</style>
