/**
 * @description 公共方法封装
 * @author YouRan
 */

import { ImagePreview } from 'vant'

// 根据浏览器设备跳转
export class IsSystemBrowser {
  // 判断设备是否是PC端
  isPcFn () {
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? window.location = 'http://m.daxinbuye.com/' : window.location = 'http://www.daxinbuye.com/'
  }
  // 非H5浏览器 - 判断是否在微信浏览器中打开
  isWeChat () {
    let ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) === 'micromessenger' || ua.match(/_SQ_/i) === '_sq_') {
      this.isWeChatHideIcon = false
      return true
    } else {
      this.isWeChatHideIcon = true
    }
  }
}

// 其他公共方法
export class OtherCommonFn {
  // 图片预览
  isImagePreviewFn (imgUrlArr, closeable = true, index = 0) {
    ImagePreview({
      images: imgUrlArr,
      closeable,
      startPosition: index
    })
  }
  // 截取某串字符串前面的所有字符
  isSubstingFn (whichOne, num, str) {
    let val = str.indexOf(whichOne)
    return str.substring(num, val)
  }
  // 深拷贝
  deepClone (oldData = {}) {
    if (typeof oldData !== 'object' || oldData === null) return oldData
    let result
    oldData instanceof Array ? result = [] : result = {}
    for (let key in oldData) result[key] = this.deepClone(oldData[key])
    return result
  }
  // 获取到年份
  getDataFn () {
    const datetime = new Date()
    let year = datetime.getFullYear()
    return year
  }
  // 防抖
  debounce (fn, delay, ...arg) {
    let timer = null
    return function (e) {
      clearTimeout(timer)
      timer = setTimeout(() => fn.call(this, e, ...arg), delay)
    }
  }
  // 存储缓存
  setStorage (key, name) {
    return window.localStorage.setItem(key, name)
  }
  // 清楚缓存
  removeStorage (name) {
    return window.localStorage.removeItem(name)
  }
  // 获取存储
  getStorage (name) {
    return window.localStorage.getItem(name)
  }
}
