/**
 * @description 用于存放公共常量数据配置
 * @author YouRan
 */
import { WX_MALL, ALI_MALL_1688, WX_OFFICIAL_ACCOUNT, DOUYIN_MALL } from '@/utils/imageManage'
import { OtherCommonFn } from '@/assets/js/common'
/* 公共 */
/* foot */
export const copyRight = `
  <p>Copyright © ${new OtherCommonFn().getDataFn()} 广东鸿仁大新纺织品</p>
  <p>有限公司. All Rights Reserved.</p>
  <p>
    <a href="https://beian.miit.gov.cn/">粤ICP备2021006138号-1</a>
  </p>
  `
// Nav
export const navData = [
  {
    id: 1,
    name: '首页',
    pageUrl: '/'
  },
  {
    id: 2,
    name: '商品展示',
    pageUrl: '/product'
  },
  {
    id: 3,
    name: '最新动态',
    pageUrl: '/news'
  },
  {
    id: 4,
    name: '公司简介',
    pageUrl: '/introduce'
  },
  {
    id: 5,
    name: '联系我们',
    pageUrl: '/contact'
  }
]

/* 首页 */
// swiper 3D相册
export const SWIPER_OPTION = {
  loop: true,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: false
  },
  effect: 'coverflow',
  slidesPerView: 3,
  coverflowEffect: {
    rotate: 30,
    stretch: 6,
    depth: 60,
    modifier: 2,
    slideShadows: false
  }
}
// 视频播放
export const PLAYER_OPTIONS = (src, poster) => {
  return {
    fluid: true,
    aspectRatio: '16:9',
    sources: [{
      type: 'video/mp4',
      src
    }],
    poster,
    notSupportedMessage: '此视频暂无法播放，请稍后再试'
  }
}

/* 公司介绍 */
export const largeImgList = [
  {
    id: 1,
    name: '厂家直供'
  },
  {
    id: 2,
    name: '现货供应'
  },
  {
    id: 3,
    name: '10万方仓储'
  },
  {
    id: 4,
    name: '5家直营店'
  },
  {
    id: 5,
    name: '甄选新疆精棉'
  }
]

/* 产品 */
// 二维码列表
export const qrCodeData = [
  {
    id: 1,
    qrcodeImg: WX_MALL,
    name: '微信小程序商城',
    des: '微信扫一扫'
  },
  {
    id: 2,
    qrcodeImg: ALI_MALL_1688,
    name: '1688 商城',
    des: '支付宝扫码'
  },
  {
    id: 3,
    qrcodeImg: WX_OFFICIAL_ACCOUNT,
    name: '微信公众号',
    des: '微信扫一扫'
  },
  {
    id: 4,
    qrcodeImg: DOUYIN_MALL,
    name: '抖音',
    des: '抖音扫一扫'
  }
]

/* 联系我们 */
export const wxNumber = '13533313570'
