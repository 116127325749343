/**
 * @description js公共meta标签管理模块
 */

// html meta 公共标签
const metaLabel = [
  {
    charset: 'utf-8'
  },
  {
    name: 'X-UA-Compatible',
    content: 'IE=edg'
  },
  {
    name: 'renderer',
    content: 'webkit'
  },
  {
    name: 'msapplication-tap-highlight',
    content: 'no'
  },
  {
    name: 'format-detection',
    content: 'telephone=no'
  },

  {
    name: 'apple-mobile-web-app-capable',
    content: 'yes'
  },
  {
    httpEquiv: 'X-UA-Compatible',
    content: 'IE=EDGE, chrome=1'
  },
  {
    name: 'viewport',
    content: 'width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
  },
  {
    name: 'apple-mobile-web-app-status-bar-style',
    content: 'black-translucent'
  },
  {
    name: 'keyWords',
    content: '大新布业官方网站,鸿仁大新,大新布业,广东鸿仁大新纺织品有限公司,大新布行,广东大新布业,广州大新布业'
  },
  {
    name: 'description',
    content: '广东鸿仁大新纺织品有限公司成立于1991年，是一家集研发、生产、销售、贸易、物流于一体的大型专业针织面料企业。只要经营各种单面、双面、罗纹、卫衣、磨毛、抓毛、31888校服等高品质时尚针织面料。产品远销海内外，赢得大批服装品牌商的青睐和首肯，年生产量超过35000吨。'
  }
]

module.exports = metaLabel
